//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { registeredBanner } from "@debionetwork/ui-icons";
import Kilt from "@kiltprotocol/sdk-js";
import { u8aToHex } from "@polkadot/util";
import CryptoJS from "crypto-js";
import { downloadFile, decryptFile, downloadDocumentFile } from "@/common/lib/pinata-proxy";
export default {
  name: "StepperStatusCard",
  data: () => ({
    registeredBanner,
    orderId: null,
    status: null,
    message: null,
    isLoading: false,
    activeStep: 1,
    orderDetail: {
      Registered: {
        status: "Awaiting Confirmation",
        message: "Your request has been sent.",
        active: 1
      },
      Rejected: {
        status: "Order Rejected",
        message: "Your request has been rejected by Genetic Analyst.",
        active: 1
      },
      InProgress: {
        status: "Confirmation Order",
        message: "Your request has been registered. You may send your sample to selected lab.",
        active: 2
      },
      ResultReady: {
        status: "Confirmation Order",
        message: "Your request being analyzed",
        active: 3
      }
    },
    orderStatus: null,
    stepper: ["Order Confirmation", "Analyzed", "Result Ready"],
    trackingId: "",
    publicKey: null,
    secretKey: null
  }),
  props: {
    analysis: Object
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      mnemonicData: state => state.substrate.mnemonicData
    }),
    computeStatus() {
      var _this$analysis, _this$analysis2;
      return (_this$analysis = this.analysis) !== null && _this$analysis !== void 0 && _this$analysis.status ? (_this$analysis2 = this.analysis) === null || _this$analysis2 === void 0 ? void 0 : _this$analysis2.status : "Registered";
    }
  },
  methods: {
    setClass(step) {
      var _this$analysis3;
      if (this.orderDetail[(_this$analysis3 = this.analysis) === null || _this$analysis3 === void 0 ? void 0 : _this$analysis3.status].active >= step) {
        var _this$analysis4;
        if (((_this$analysis4 = this.analysis) === null || _this$analysis4 === void 0 ? void 0 : _this$analysis4.status) === "Rejected") {
          return ["step-icon", "border-error", "error"];
        }
        return ["step-icon", "active"];
      }
      return "step-icon";
    },
    async cancelOrder() {
      this.$emit("cancel");
    },
    async viewResult() {
      var _this$analysis5;
      if (((_this$analysis5 = this.analysis) === null || _this$analysis5 === void 0 ? void 0 : _this$analysis5.status) === "Rejected") {
        this.$emit("reject");
        return;
      }
      this.$router.push({
        name: "customer-genetic-analysis-result",
        params: {
          id: this.$route.params.id
        }
      });
      const pair = {
        publicKey: this.analysis.info.boxPublicKey,
        secretKey: this.secretKey
      };
      const type = "application/pdf";
      const {
        data
      } = await downloadFile(this.reportLink);
      const decryptedFile = decryptFile(data, pair, type);
      await downloadDocumentFile(decryptedFile, this.reportLink.split("/").pop(), type);
    },
    async initialData() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
    }
  }
};
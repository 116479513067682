//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ServiceCard",
  props: {
    service: Object
  },
  computed: {
    computeAvatar() {
      var _this$service, _this$service2;
      return (_this$service = this.service) !== null && _this$service !== void 0 && _this$service.analystProfileImage ? (_this$service2 = this.service) === null || _this$service2 === void 0 ? void 0 : _this$service2.analystProfileImage : require("@/assets/defaultAvatar.svg");
    }
  }
};
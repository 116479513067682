//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ReasonOfRejection",
  props: {
    show: Boolean,
    title: String,
    message: String
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    onSubmit() {
      this.$emit("click");
    }
  }
};